<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5 class="mb-4">
								<strong>Resumen de Ingresos</strong>
							</h5>
						</div>
						<div class="col-md-6 text-right">
							<a-button class="btn btn-info" icon="plus" @click="onAddRecord">Añadir</a-button>
							<a-button class="btn btn-success ml5" icon="reload" @click="onReload" />
						</div>
					</div>
					<a-table :columns="columns" :dataSource="businessAreasList" rowKey="id">
						<span slot="active" slot-scope="record">
							<a-tag color="green" v-if="record.active">Activo</a-tag>
							<a-tag color="red" v-else>Inactivo</a-tag>
						</span>
						<span slot="action" slot-scope="record">
							<a-button type="info" size="small" icon="edit" @click="onEditRecord(record)" />
							<a-button class="ml5" size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="onDisableRecord(record.id, record.active)" />
						</span>
					</a-table>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { adminsList } from '@/constants'
import Swal from 'sweetalert2'
import numeral from 'numeral'

const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'incomesView',
	components: {},
	data() {
		return {
			columns: [
				{
					title: 'ID / Movimiento',
					align: 'center',
					scopedSlots: { customRender: 'id' },
				},
				{
					title: 'Monto / Estatus',
					align: 'center',
					scopedSlots: { customRender: 'status' },
				},
				{
					title: 'Entrega',
					dataIndex: 'issuingName',
					align: 'center',
				},
				{
					title: 'Recibe',
					dataIndex: 'receiverName',
					align: 'center',
				},
				{
					title: 'Comentarios',
					dataIndex: 'comments',
					align: 'center',
					width: '30%',
					ellipsis: true,
				},
				{
					title: 'Fecha',
					align: 'center',
					scopedSlots: { customRender: 'created_at' },
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			],
			dateFormat,
			modalReceipt: {
				visible: false,
			},
		}
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('cashFlow', ['cashFlowList', 'summaryData']),
		...mapGetters('purchaseOrders', ['filters']),
		spinnerLoaderLabel() {
			return this.$store.state.cashFlow.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.cashFlow.spinnerLoader
		},
		actualUser() {
			return !!adminsList.find((e) => e.id == this.user.user_id)
		},
	},
	beforeMount() {
		// obtenemos listado de operaciones del mes
		if (this.cashFlowList.length == 0) {
			this.$store.dispatch('cashFlow/GET')
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		moment,
		numeral,
		initModule(date, dateString) {
			//
			let payload = {}

			if (!!dateString && dateString.length) {
				payload = {
					startDate: dateString[0],
					endDate: dateString[1],
				}
			} else {
				payload = {
					startDate: moment(this.filters.defaultDate[0]).format(dateFormat),
					endDate: moment(this.filters.defaultDate[1]).format(dateFormat),
				}
			}

			// obtenemos resumen de flujo
			this.$store.dispatch('cashFlow/GET_SUMMARY', payload)
		},
		onReload() {
			this.$store.dispatch('cashFlow/GET')
		},
		onAddRecord() {
			this.$router.push('/operations/cash-flow/create')
		},
		onEditRecord(index) {
			let payload = {
				index,
				requireRouter: true,
			}
			this.$store.dispatch('cashFlow/SET_LOCAL', payload)
		},
		authorizeCashflow(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas autorizar?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			})
				.then((result) => {
					if (result.isConfirmed) {
						Swal.fire({
							title: 'Ingresa contraseña para confirmar la operación',
							input: 'password',
							inputLabel: 'Password',
							inputPlaceholder: 'Ingresa tu contraseña',
							inputAttributes: {
								autocapitalize: 'off',
								autocorrect: 'off',
								autocomplete: 'off',
							},
							inputValidator: (value) => {
								return new Promise((resolve) => {
									if (value) {
										resolve()
									} else {
										resolve('Es necesario ingresar contraseña')
									}
								})
							},
						}).then((resultPassword) => {
							if (resultPassword.isConfirmed) {
								let payload = {
									id,
									authPass: resultPassword.value,
								}

								this.$store.dispatch('cashFlow/CONFIRM', payload)
							}
						})
					}
				})
				.catch((error) => {
					console.log('authorizeCashflow::error-->', error)
				})
		},
		handleCancelReceipt() {
			this.modalReceipt.visible = false
		},
		onSetReceipt(index) {
			let payload = {
				index,
				requireRouter: false,
			}
			this.$store.dispatch('cashFlow/SET_LOCAL', payload)
			setTimeout(() => {
				this.modalReceipt.visible = true
			}, 100)
		},
	},
}
</script>